import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, TextField, Typography } from "@mui/material";
import { ErrorText, FormSubmitButtonContainer, InputRow } from "../styled";
import { AutofillContext } from "../contexts/AutofillContext";
import { StateContext } from "../contexts/StateContext";

const fieldRows = [
  [
    {
      key: "street1",
      label: "Street 1",
      required: true,
    },
    {
      key: "street2",
      label: "Street 2",
    },
    {
      key: "city",
      label: "City",
      required: true,
    },
  ],
  [
    {
      key: "region",
      label: "State",
      required: true,
    },
    {
      key: "country",
      label: "Country",
      required: true,
    },
    {
      key: "postal_code",
      label: "Zip Code",
      required: true,
    },
  ],
];

export const AddressInformationFormBody = ({ setPage }) => {
  const { state, setState } = useContext(StateContext);
  const { setCallback, clearCallback } = useContext(AutofillContext);
  const [hasError, setHasError] = useState(false);

  const onChange = (field) => (event) => {
    event.persist();
    setState({ ...state, [field]: event.target.value });
  };

  useEffect(() => {
    setCallback(() =>
      setState((s) => ({
        ...s,
        street1: "600 Montgomery St",
        street2: "Suite 600",
        city: "San Francisco",
        region: "California",
        country: "United States",
        postal_code: "94111",
      }))
    );

    return () => clearCallback();
  }, [setCallback, clearCallback, setState]);

  const valid = useMemo(
    () =>
      fieldRows
        .flat()
        .filter(({ required }) => required)
        .reduce((a, c) => a && state[c.key].length, true),
    [state]
  );

  const onNext = useCallback(() => {
    if (!valid) {
      setHasError(true);
    } else {
      setHasError(false);
      setPage("accounts");
    }
  }, [setPage, valid]);

  return (
    <Fragment>
      <Typography variant="h4">Address Information</Typography>
      {fieldRows.map((fields) => (
        <InputRow>
          {fields.map((field) => (
            <TextField
              {...field}
              fullWidth
              value={state[field.key]}
              onChange={onChange(field.key)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ))}
        </InputRow>
      ))}
      <FormSubmitButtonContainer>
        <Button
          variant="contained"
          size="large"
          onClick={() => setPage("personal")}
        >
          Back
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        {hasError ? (
          <ErrorText>Please fill all required fields.</ErrorText>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onNext}
          disabled={!valid}
        >
          Next
        </Button>
      </FormSubmitButtonContainer>
    </Fragment>
  );
};
