import { Button, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useCallback, useContext } from "react";
import { StateContext } from "../contexts/StateContext";
import { FormSubmitButtonContainer } from "../styled";

export const VerifyEmailFormBody = () => {
  const { state } = useContext(StateContext);

  const onClick = useCallback(() => {
    const cb = () => navigate(`/login`);

    fetch(`/api/v1/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-SPEC-PAYMENT-TYPE": "ach",
      },
      body: JSON.stringify(state),
    })
      .then(cb)
      .catch(cb);
  }, [state]);

  return (
    <Fragment>
      <Typography variant="h4">Account setup almost complete!</Typography>
      <Typography variant="body1">
        You have been sent a verification email to confirm your account. Once
        you open the link in that email, you can log in to your new account by
        following the link below.
      </Typography>
      <FormSubmitButtonContainer>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onClick}
        >
          Log In
        </Button>
      </FormSubmitButtonContainer>
    </Fragment>
  );
};
