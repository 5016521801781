import React from "react";

import "../index.css";
import { RegisterCard } from "../components/RegisterCard";
import { BankWrapper } from "../components/BankWrapper";

export default function RegisterPage() {
  return (
    <BankWrapper>
      <RegisterCard />
    </BankWrapper>
  );
}
