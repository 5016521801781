import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, TextField, Typography } from "@mui/material";
import { ErrorText, FormSubmitButtonContainer, InputRow } from "../styled";
import { AutofillContext } from "../contexts/AutofillContext";
import { StateContext } from "../contexts/StateContext";

const fieldRows = [
  [
    {
      key: "routingNum",
      label: "Routing Number",
      required: true,
      type: "number",
    },
    {
      key: "accountNum",
      label: "Account Number",
      required: true,
      type: "number",
    },
    {
      key: "name",
      label: "Nickname",
      required: true,
    },
  ],
];

export const BankInformationFormBody = ({ setPage, fireRequest }) => {
  const { setState } = useContext(StateContext);
  const { setCallback, clearCallback } = useContext(AutofillContext);
  const [hasError, setHasError] = useState(false);
  const [internalState, setInternalState] = useState({
    routingNum: "",
    accountNum: "",
    name: "",
  });

  const onChange = (field) => (event) => {
    event.persist();

    setInternalState({ ...internalState, [field]: event.target.value });
  };

  useEffect(() => {
    setCallback(() => {
      setInternalState({
        routingNum: "12210003945",
        accountNum: "2198472950",
        name: "Chase Savings Account",
      });
    });

    return () => clearCallback();
  }, [setCallback, clearCallback]);

  const valid = useMemo(
    () =>
      fieldRows
        .flat()
        .filter(({ required }) => required)
        .reduce((a, c) => a && internalState[c.key].length, true),
    [internalState]
  );

  const onAdd = useCallback(() => {
    if (!valid) {
      setHasError("Please fill all required fields.");
    } else {
      setHasError(false);

      if (fireRequest) {
        fetch(`/api/v1/add_bank_account`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-SPEC-PAYMENT-TYPE": "ach",
          },
          body: JSON.stringify(internalState),
        })
          .then((response) => {
            if (response.ok) {
              setPage("accounts");
              setState((s) => ({
                ...s,
                accounts: s.accounts.concat(internalState),
              }));
            } else {
              throw new Error();
            }
          })
          .catch(() => setHasError("Failed to add account."));
      } else {
        setPage("accounts");
        setState((s) => ({
          ...s,
          accounts: s.accounts.concat(internalState),
        }));
      }
    }
  }, [setPage, setState, internalState, valid, fireRequest]);

  return (
    <Fragment>
      <Typography variant="h4">Add External Account</Typography>
      <Typography variant="body1">
        Enter bank account information below.
      </Typography>
      {fieldRows.map((fields) => (
        <InputRow>
          {fields.map((field) => (
            <TextField
              {...field}
              fullWidth
              value={internalState[field.key]}
              onChange={onChange(field.key)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ))}
        </InputRow>
      ))}
      <FormSubmitButtonContainer>
        <Button
          variant="contained"
          size="large"
          onClick={() => setPage("accounts")}
        >
          Back
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        {hasError ? <ErrorText>{hasError}</ErrorText> : null}
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onAdd}
          disabled={!valid}
        >
          Add
        </Button>
      </FormSubmitButtonContainer>
    </Fragment>
  );
};
