import {
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Icon,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { StateContext } from "../contexts/StateContext";
import { FormSubmitButtonContainer } from "../styled";

export const ConnectAccountsFormBody = ({ setPage }) => {
  const { state, setState } = useContext(StateContext);
  const [shouldVerify, setShouldVerify] = useState(false);

  /**
   * If mitigations are enabled on the server, we want to forward users to the
   * document verification URL. This URL servers a static page to verify a users
   * identity using Socure Document Verification.
   *
   * This effect reaches out to the server to see if mitigations are enabled,
   * then saves that to state.
   *
   * We log the status to aid in troubleshooting.
   */
  useEffect(() => {
    fetch(`/api/v1/mitigate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(({ mitigate }) => {
        if (mitigate) {
          setShouldVerify(true);
          console.log(
            "Mitigations enabled, will route to document verification."
          );
        } else {
          console.log(
            "Mitigations disabled, will not route to document verification."
          );
        }
      })
      .catch(console.error);
  }, []);

  const onDelete = (index) => () => {
    setState({
      ...state,
      accounts: state.accounts.filter((...args) => args[1] !== index),
    });
  };

  /**
   * If mitigations are enabled on the server, we do a hard redirect to the
   * static page for document verification, otherwise we continue on in the form
   * flow as typical.
   */
  const onNext = () => {
    if (shouldVerify) {
      window.location = `${window.location.origin}/document-verification`;
    } else {
      setPage("verify");
    }
  };

  return (
    <Fragment>
      <Typography variant="h4">Connect External Accounts</Typography>
      <Typography variant="body1">
        You can link external accounts to transfer funds or to set up bill pay.
        If you want to set this up later, you can do so in the settings screen.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setPage("add-account")}
      >
        Add External Account
      </Button>
      {state.accounts.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Routing Number</TableCell>
                <TableCell align="right">Account Number</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.accounts.map((acc, index) => (
                <TableRow key={acc.name}>
                  <TableCell component="th" scope="row">
                    {acc.name}
                  </TableCell>
                  <TableCell align="right">{acc.routingNum}</TableCell>
                  <TableCell align="right">{acc.accountNum}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={onDelete(index)}>
                      <Icon>delete</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      <FormSubmitButtonContainer>
        <Button
          variant="contained"
          size="large"
          onClick={() => setPage("address")}
        >
          Back
        </Button>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onNext}
        >
          Next
        </Button>
      </FormSubmitButtonContainer>
    </Fragment>
  );
};
